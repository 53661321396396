<!--suppress XmlHighlighting, XmlHighlighting -->
<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="24">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>改价申请记录</span>
            <el-button type="text" icon="el-icon-refresh" @click="getApplyList" :loading="loading"></el-button>
          </div>
          <div>
            <el-table :data="applyRecords" style="width: 100%" border>
              <el-table-column label="基本信息" header-align="center" align="left" min-width="160">
                <template slot-scope="{row}">
                  <div style="display: flex;flex-direction: column;text-align: left">
                    <div><span class="is-label">申请人：</span>{{ row.user_name }}</div>
                    <div><span class="is-label">申请时间：</span>{{ row.created_at }}</div>
                    <div><span class="is-label">状态：</span>
                      <el-tag size="small" :type="calcApplyStatus(row.status)" effect="plain">{{
                          row.status_alias
                        }}
                      </el-tag>
                    </div>
                    <div v-if="row.checker_id">
                      <span class="is-label">审核人：</span>{{ row.checker_name }}
                      <br />
                      <span class="is-label">审核时间：</span>{{ row.updated_at }}
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="current_quote" label="当月价格" align="center" min-width="240"
                  :show-overflow-tooltip="true">
                <template slot-scope="{row,column}">
                  <PriceGrids :rows="row[column.property]" />
                </template>
              </el-table-column>
              <el-table-column prop="next_quote" label="下月预估价" align="center" min-width="240"
                  :show-overflow-tooltip="true">
                <template slot-scope="{row,column}">
                  <PriceGrids :rows="row[column.property]" />
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" min-width="120">
                <template slot-scope="{row,$index}">
                  <el-popover
                      placement="left-start"
                      title="同意改价"
                      width="300"
                      trigger="hover"
                      content='同意后，刊例的"商业化营销中心终价"将被替换为申请改价的"下月预估价"'>
                    <el-button slot="reference" size="large" type="success" icon="el-icon-check" circle
                        @click="handleCheck(row,$index,2)" v-if="row.status===1">
                    </el-button>
                  </el-popover>
                  &nbsp;
                  &nbsp;
                  <el-popover
                      placement="left-start"
                      title="拒绝改价"
                      width="300"
                      trigger="hover"
                      content='拒绝后，刊例的"商业化营销中心终价"将不变'>
                    <el-button slot="reference" size="large" type="danger" icon="el-icon-close" circle
                        @click="handleCheck(row,$index,3)" v-if="row.status===1">
                    </el-button>
                  </el-popover>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-card>

      </el-col>
    </el-row>

  </div>
</template>

<script>
import PriceGrids from '@/pages/publication/PriceGrids'

export default {
  name: "ApplyPanel",
  components: {PriceGrids},
  props:{
    plcItemId:{
      type:[Number,String],
      default(){
        return null
      }
    }
  },
  data() {
    return {
      loading: false,
      applyRecords: [],
    }
  },
  methods: {
    calcApplyStatus(status) {
      //`${scope.row.apply_status===3?'success':'danger'}`
      switch (status) {
        case 1:
          return 'primary'
        case 2:
          return 'success'
        case 3:
          return 'danger'
        case 4:
          return 'danger'
        case 5:
          return ''
        default:
          return 'info'
      }
    },
    async getApplyList() {
      this.loading = true
      this.applyRecords = []
      let id = this.plcItemId || null
      let {list} = await this.$api.getPlcItemApplyRecord(id)
      this.applyRecords = list
      this.loading = false
    },
    async handleCheck(row, index, statusCode) {
      await this.$api.handlePlcItemApplyRecord({id: row.id, status: statusCode})
      await this.getApplyList()
      if (statusCode === 2) {
        await this.getItemInfo()
      }
    },
  },
  created() {
    this.getApplyList()
  }
}
</script>

<style scoped>

</style>
